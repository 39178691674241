import { Typography } from "@mui/material";
import CreditCardOffRoundedIcon from "@mui/icons-material/CreditCardOffRounded";
import theme from "@/styles/theme";
import { useTranslation } from "next-i18next";

export const TrialsCreditCard = () => {
  const { t } = useTranslation("trials");
  return (
    <>
      <CreditCardOffRoundedIcon
        sx={{
          marginRight: theme.spacing(1.5),
          color: theme.palette.black[600],
          height: "16px",
          width: "16px",
        }}
      />

      <Typography variant="bodyM" sx={{ color: theme.palette.black[600] }}>
        {t("trials.modal.no.credit.card")}
      </Typography>
    </>
  );
};
