import styled from "@emotion/styled";
import theme from "@/styles/theme";
import { Typography } from "@mui/material";

const CustomContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    padding: ${(p) => p.theme.spacing(2)};
    width: 100%;
    flex-direction: column;
  }
`;

export const TitleArea = styled.div`
  && {
    max-width: 648px;
    text-align: center;

    ${theme.breakpoints.down("md")} {
      max-width: 456px;
    }

    ${theme.breakpoints.down("sm")} {
      max-width: 100%;
    }
  }
`;

export const PricingTitle = styled(Typography)`
  && {
    font-size: 72px;
    font-weight: 700;
    line-height: 56px;

    ${theme.breakpoints.down("sm")} {
      font-size: 40px;
    }
  }
`;

export const PlansArea = styled.div`
  && {
    max-width: 1440px;
    margin: ${(p) => p.theme.spacing(6, "auto", 3, "auto")};

    ${theme.breakpoints.down("lg")} {
      max-width: 100%;
    }
  }
`;

export const PlansAreaTop = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${(p) => p.theme.spacing(2)};
  }
`;

export const PlansAreaBottom = styled.div`
  && {
    margin-top: ${(p) => p.theme.spacing(5)};
    display: flex;
    justify-content: center;

    ${theme.breakpoints.down("lg")} {
      margin-top: ${(p) => p.theme.spacing(2)};
    }
  }
`;

export default CustomContainer;
