"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { PricingMainWrapper } from "@/app/(pages)/page.styles";
import { useTranslation } from "next-i18next";
import { Typography } from "@mui/material";
import CustomContainer, {
  TitleArea,
  PlansArea,
  PlansAreaTop,
  PlansAreaBottom,
  PricingTitle,
} from "@/app/(pages)/pricing/page.styles";
import theme from "@/styles/theme";
import PlanCard from "@/components/pricing/PlanCard";
import { useAppDispatch, useAppSelector } from "@/store";
import {
  plans,
  colorMapper,
  PlanTypes,
  productIdMapper,
} from "@/common/constants/pricingConstants";
import {
  selectProductFeatures,
  setProductFeatures,
} from "@/store/slices/pricing.slice";
import { Footer } from "@/components/shared/footer/Footer";
import { ENV_CONSTANTS } from "@/common/constants/env.const";
import {
  fetchErrorHandler,
  nextErrorHandler,
} from "@/common/utils/nextErrorHandler";
import routes from "@/common/constants/routes";

const PricingBody = () => {
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const { t } = useTranslation("pricing");
  const productFeatures = useAppSelector(selectProductFeatures);
  const promoText = ENV_CONSTANTS.PRICING_PAGE_PROMO;

  useEffect(() => {
    if (!productFeatures.length) {
      (async () => {
        try {
          const response = await fetch(`/api/pricing_table`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          const responseData = await response.json();

          if (!response.ok) {
            throw fetchErrorHandler(responseData);
          }

          await dispatch(setProductFeatures(responseData.products.data));
        } catch (err: unknown) {
          return nextErrorHandler(err);
        }
      })();
    }
  });

  const getFeatures = (plan: PlanTypes): string[] => {
    const product = productFeatures.find(
      (product) => product.id === productIdMapper[plan],
    );

    return product
      ? product.marketing_features.map((feature) => feature.name)
      : [];
  };

  return (
    <PricingMainWrapper $pathname={pathName}>
      <CustomContainer>
        <TitleArea>
          <PricingTitle variant="displayM" data-testid="dti-pricing-title">
            {t("pricing.title")}
          </PricingTitle>
          <Typography
            variant="titleMA"
            color={theme.palette.black[800]}
            padding={promoText ? theme.spacing(3, 0) : theme.spacing(3, 0, 0)}
            data-testid="dti-pricing-subtitle"
          >
            {t("pricing.subtitle")}
          </Typography>
          {promoText && (
            <Typography
              variant="labelL"
              sx={{
                color: theme.palette.black[800],
                padding: theme.spacing(0.25, 1),
                background: "rgba(255, 234, 115, 0.7)",
              }}
              data-testid="dti-pricing-subtitle"
            >
              {promoText}
            </Typography>
          )}
        </TitleArea>

        <PlansArea>
          <PlansAreaTop>
            {Object.values(plans)
              .filter((plan) => plan !== "enterprise")
              .map((plan) => (
                <PlanCard
                  key={`plan-${plan}`}
                  plan={plan}
                  title={t(`pricing.plan.${plan}.title`)}
                  subtitle={t(`pricing.plan.${plan}.subtitle`)}
                  featureTitle={t(`pricing.plan.${plan}.features.title`)}
                  features={getFeatures(plan)}
                  themeColor={colorMapper[plan]}
                />
              ))}
          </PlansAreaTop>
          <PlansAreaBottom>
            {Object.values(plans)
              .filter((plan) => plan === "enterprise")
              .map((plan) => (
                <PlanCard
                  key={`plan-${plan}`}
                  plan={plan}
                  title={t(`pricing.plan.${plan}.title`)}
                  subtitle={t(`pricing.plan.${plan}.subtitle`)}
                  featureTitle={t(`pricing.plan.${plan}.features.title`)}
                  features={getFeatures(plan)}
                  themeColor={colorMapper[plan]}
                />
              ))}
          </PlansAreaBottom>
        </PlansArea>

        <Typography variant="bodyL" sx={{ margin: theme.spacing(3, 0) }}>
          {t("pricing.plan.after.trial.text")}
        </Typography>

        <Typography
          variant="bodyS"
          color={theme.palette.grey[600]}
          paddingBottom={theme.spacing(7)}
          data-testid="dti-pricing-footer"
        >
          {t("pricing.footer")}
        </Typography>
        {pathName.includes(routes.PRICING) && <Footer />}
      </CustomContainer>
    </PricingMainWrapper>
  );
};

export default PricingBody;
