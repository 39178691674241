import theme, { withTransientProps } from "@/styles/theme";
import styled from "@emotion/styled";
import { Box, Button, Grid, Icon } from "@mui/material";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

type WrapperProps = {
  $fullWidthCard: boolean;
};

export const PlanCardWrapper = styled("div", withTransientProps)<WrapperProps>`
  && {
    position: relative;
    background-color: #ffffff;
    border-radius: ${(p) => p.theme.spacing(2)};
    width: calc((100% - 3 * 16px) / 4);

    ${(p) => !p.$fullWidthCard && `padding: ${p.theme.spacing(3)};`}
    ${(p) =>
      p.$fullWidthCard &&
      `width: 65%; padding: ${p.theme.spacing(
        7,
        9,
      )}; display: flex; gap: ${p.theme.spacing(6)};`}

    &:last-child {
      margin-right: 0;
    }

    ${theme.breakpoints.down("lg")} {
      ${(p) =>
        !p.$fullWidthCard &&
        `width: calc((100% - 16px) / 2); margin-bottom: ${p.theme.spacing(1)};`}
      ${(p) => p.$fullWidthCard && `width: 100%;`}
    }

    ${theme.breakpoints.down("sm")} {
      width: 100%;

      ${(p) =>
        p.$fullWidthCard &&
        `padding: ${p.theme.spacing(3, 3, 6)}; display: block;`}
    }
  }
`;

export const StyledPlanCardDetails = styled(Box)`
  && {
    flex: 1;
  }
`;

export const StyledIcon = styled(Icon)`
  && {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${(p) => p.theme.spacing(2)};
  }
`;

type StyledButtonProps = {
  $textColor?: string;
  $isSignUpButton?: boolean;
  $isLoading?: boolean;
  $fullWidthCard: boolean;
  $currentPlan: string;
  $plan: string;
  $trialDone: boolean;
  $inTrial: boolean;
  $customerId: boolean;
  $trialAvailable: boolean;
};

export const StyledPlanButton = styled(
  Button,
  withTransientProps,
)<StyledButtonProps>`
  && {
    width: ${(p) => (p.$fullWidthCard ? "230px" : "100%")};
    margin-top: ${(p) => p.theme.spacing(1.5)};
    background-color: #fff;
    border-color: ${(p) => p.theme.palette.black[300]};
    color: ${(p) => p.theme.palette.black[800]};

    // Disabled state
    &:disabled {
      border-color: ${(p) => p.theme.palette.black[100]};
      color: ${(p) => p.theme.palette.grey[600]};
    }

    // Active state not logged in not basic
    ${(p) =>
      !p.$isLoading &&
      p.$currentPlan === p.$plan &&
      !p.$inTrial &&
      `background-color: #ffffff color: ${p.theme.palette.black[800]}; border-color: ${p.theme.palette.black[300]};`}

    ${(p) =>
      !p.$isLoading &&
      !p.$customerId &&
      p.$trialAvailable &&
      `background-color: ${p.$textColor}; color: #ffffff; border-color: ${p.$textColor};`}

      ${(p) =>
      !p.$isLoading &&
      p.$currentPlan === p.$plan &&
      p.$inTrial &&
      `background-color: ${p.$textColor}; color: #ffffff; border-color: ${p.$textColor};`}

      ${(p) =>
      !p.$isLoading &&
      !p.$inTrial &&
      p.$trialDone &&
      p.$isSignUpButton &&
      `background-color: ${p.$textColor}; color: #ffffff; border-color: ${p.$textColor};`} 

    ${theme.breakpoints.down("sm")} {
      ${(p) => p.$fullWidthCard && `width: 100%`}
    }
  }
`;

export const StyledFeatures = styled(Grid)`
  && {
    margin-top: ${(p) => p.theme.spacing(1)};
    align-items: flex-start;
    flex-wrap: nowrap;
  }
`;

export const StyledPlanPrice = styled(Box)`
  && {
    padding-right: ${(p) => p.theme.spacing(2)};
  }
`;

export const TrialsCardContainer = styled("div")`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${(p) => p.theme.spacing(2)};
  }
`;

export const PromoOfferContainer = styled("div")`
  && {
    border: 1px solid ${(p) => p.theme.palette.grey[200]};
    border-radius: ${(p) => p.theme.spacing(1)};
    padding: ${(p) => p.theme.spacing(2)};
    margin: ${(p) => p.theme.spacing(3, 0, 0)};
  }
`;

export const LocalOfferOutlinedIconStyled = styled(LocalOfferOutlinedIcon)`
  && {
    margin: ${(p) => p.theme.spacing(0, 1, 1, 0)};
    width: 16px;
    height: 16px;
    color: #925eff;
  }
`;
