"use client";

import { Typography, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  PlanCardWrapper,
  StyledPlanButton,
  StyledIcon,
  StyledFeatures,
  TrialsCardContainer,
  PromoOfferContainer,
  LocalOfferOutlinedIconStyled,
  StyledPlanCardDetails,
} from "@/components/pricing/PlanCard.styles";
import theme from "@/styles/theme";
import CheckIcon from "@mui/icons-material/Check";
import {
  priceMapper,
  PlanTypes,
  planAvailability,
  plans,
  activePlans,
} from "@/common/constants/pricingConstants";
import { useRouter } from "next/navigation";
import { useAppDispatch, useAppSelector } from "@/store";
import {
  selectIsLoggedIn,
  selectUserPayment,
  selectUserPlan,
} from "@/store/slices/auth.slice";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { setPricePlan } from "@/store/slices/pricing.slice";
import { TrialsCreditCard } from "../shared/trials/components/TrialsCreditCard";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import routes from "@/common/constants/routes";

export type PlanCardProps = {
  title: string;
  subtitle: string;
  featureTitle: string;
  features: string[];
  plan: PlanTypes;
  themeColor: string;
};

interface UserPlan {
  planName: string;
  trial: string;
}

interface UserPayment {
  customerKey: string;
}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  subtitle,
  featureTitle,
  features,
  plan,
  themeColor,
}: PlanCardProps) => {
  const { t } = useTranslation("pricing");
  const router = useRouter();
  const dispatch = useAppDispatch();

  const availableTrials = [plans.SOLO];

  const isSignUpButton = activePlans.includes(plan);
  const isBasicPlan = plan === plans.BASIC;
  const isEnterPrisePlan = plan === plans.ENTERPRISE;

  const userPlan = useAppSelector(selectUserPlan) as UserPlan;
  const isloggedIn = useAppSelector(selectIsLoggedIn);

  const [isLoading, setIsloading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [currentPlan, setCurrentPlan] = useState("");
  const [buttonText, setButtonText] = useState("");

  const payment = useAppSelector(selectUserPayment) as UserPayment;

  useEffect(() => {
    if (userPlan) {
      const currentUserPlan =
        userPlan.planName === "registered-user"
          ? plans.BASIC
          : userPlan.planName;
      setCurrentPlan(currentUserPlan);
    }
  }, [userPlan, payment]);

  useEffect(() => {
    const storePlan = localStorage.getItem("pricePlan");
    const customerId = payment?.customerKey;
    const inTrial = userPlan?.trial;
    const trialDone = currentPlan !== plan && customerId;

    if (storePlan) {
      localStorage.removeItem("pricePlan");
    }

    if (isloggedIn) {
      // enterprise plan
      if (isEnterPrisePlan) {
        setIsButtonDisabled(false);
        setIsloading(false);
        return setButtonText("pricing.plan.button.enterprise");
      }
      // Is Current Plan
      if (plan === currentPlan && !inTrial) {
        setIsButtonDisabled(true);
        setIsloading(false);
        return setButtonText("pricing.plan.button.current");
      }

      // Upgrade
      if (inTrial && currentPlan === plan) {
        setIsButtonDisabled(false);
        setIsloading(false);
        return setButtonText("pricing.plan.button.upgrade");
      }

      if (!inTrial && trialDone && activePlans.includes(plan)) {
        setIsButtonDisabled(false);
        setIsloading(false);
        return setButtonText("pricing.plan.button.upgrade");
      }

      // Start Trial
      if (
        !customerId &&
        plan !== currentPlan &&
        availableTrials.includes(plan)
      ) {
        setIsButtonDisabled(false);
        setIsloading(false);
        return setButtonText("pricing.plan.button.trial");
      }
    } else {
      // enterprise plan
      if (isEnterPrisePlan) {
        setIsButtonDisabled(false);
        setIsloading(false);
        return setButtonText("pricing.plan.button.enterprise");
      }

      // Basic Sign up button
      if (isBasicPlan && activePlans.includes(plan)) {
        setIsButtonDisabled(false);
        setIsloading(false);
        return setButtonText("pricing.plan.button.default");
      }

      // Start free trial
      if (!isBasicPlan && activePlans.includes(plan)) {
        setIsButtonDisabled(false);
        setIsloading(false);
        return setButtonText("pricing.plan.button.trial");
      }
    }

    //Coming soon
    setIsButtonDisabled(true);
    setIsloading(false);
    return setButtonText("pricing.plan.button.toBeAnnounced");
  }, [userPlan, currentPlan]);

  useEffect(() => {
    if (!activePlans.includes(plan)) {
      setIsButtonDisabled(true);
    }
  }, []);

  const paymentMethod = async (selectedPlan: string) => {
    if (
      currentPlan === selectedPlan ||
      (currentPlan !== selectedPlan && payment?.customerKey)
    ) {
      return localStorage.setItem("startTrial", "false");
    }

    return localStorage.setItem("startTrial", "true");
  };

  const handleButtonClick = async (plan: string) => {
    if (isEnterPrisePlan) {
      window.location.href = "https://www.peach.me/contact/";
    }
    if (!isloggedIn && isSignUpButton) {
      localStorage.setItem("pricePlan", plan);
      paymentMethod(plan);
      return router.push(routes.REGISTER);
    }

    if (isSignUpButton && isloggedIn) {
      await dispatch(setPricePlan(plan));
      paymentMethod(plan);
      return router.push(routes.CHECKOUT);
    }
  };

  const planIcon = () => {
    if (plan === plans.SOLO) {
      return <PersonRoundedIcon fontSize="small" sx={{ color: "#fff" }} />;
    }

    if (plan === plans.PRO) {
      return <PeopleAltRoundedIcon fontSize="small" sx={{ color: "#fff" }} />;
    }

    if (plan === plans.PREMIUM) {
      return <BoltRoundedIcon fontSize="small" sx={{ color: "#fff" }} />;
    }

    if (plan === plans.ENTERPRISE) {
      return <StarRoundedIcon fontSize="small" sx={{ color: "#fff" }} />;
    }
  };

  const PlanCardHeader = () => {
    return (
      <>
        <Grid
          container
          alignItems="center"
          data-testid={`dti-${plan}-pricing-plan-title`}
        >
          {!isBasicPlan && (
            <StyledIcon
              fontSize="large"
              sx={{
                backgroundColor: themeColor,
                height: "0.9em",
                width: "0.9em",
              }}
            >
              {planIcon()}
            </StyledIcon>
          )}

          <Typography variant="hM">{title}</Typography>
        </Grid>

        <Typography
          variant="bodyL"
          marginTop={theme.spacing(2)}
          color={theme.palette.grey[600]}
          data-testid={`dti-${plan}-pricing-plan-subtitle`}
        >
          {subtitle}
        </Typography>
      </>
    );
  };

  const PlanCardPrice = () => {
    return (
      <>
        {priceMapper[plan] && (
          <Typography
            variant="hS"
            sx={{
              marginTop: theme.spacing(2),
              display: "flex",
              alignItems: "center",
            }}
          >
            {t(priceMapper[plan])}{" "}
            <Typography
              variant="bodyM"
              component="span"
              sx={{
                marginLeft: theme.spacing(0.5),
                color: theme.palette.grey[600],
              }}
            >
              {isBasicPlan
                ? t("pricing.plan.billing.basic")
                : t("pricing.plan.billing")}
            </Typography>
          </Typography>
        )}
      </>
    );
  };

  const PlanCardCTA = () => {
    const trialDone = currentPlan !== plan && payment?.customerKey;
    const inTrial = userPlan?.trial;
    const customerId = payment?.customerKey;
    const trialAvailable = availableTrials.includes(plan);
    return (
      <>
        <StyledPlanButton
          variant="outlinedSquared"
          size="large"
          disabled={isButtonDisabled}
          $isSignUpButton={isSignUpButton}
          $textColor={themeColor}
          $isLoading={isLoading}
          $fullWidthCard={plan === plans.ENTERPRISE}
          $currentPlan={currentPlan}
          $plan={plan}
          $trialDone={!!trialDone}
          $inTrial={!!inTrial}
          $customerId={!!customerId}
          $trialAvailable={trialAvailable}
          onClick={() => {
            handleButtonClick(plan);
          }}
          data-testid={`dti-${plan}-pricing-plan-button`}
        >
          {!isLoading ? t(`${buttonText}`) : <CircularProgress size={25} />}
        </StyledPlanButton>

        {!isBasicPlan && isSignUpButton && (
          <TrialsCardContainer>
            <TrialsCreditCard />
          </TrialsCardContainer>
        )}
      </>
    );
  };

  const PlanCardFeatures = () => {
    return (
      <>
        <Typography
          marginTop={theme.spacing(3)}
          marginBottom={theme.spacing(1.5)}
          variant="titleS"
          data-testid={`dti-${plan}-pricing-plan-feature-title`}
        >
          {featureTitle}
        </Typography>
        {features.length ? (
          <>
            {features.map((feature, index) => (
              <StyledFeatures
                container
                key={`${title}-feature${index}`}
                data-testid={`dti-${plan}-pricing-plan-feature${index}`}
              >
                <CheckIcon
                  fontSize="small"
                  sx={{ color: themeColor, marginRight: theme.spacing(1) }}
                />
                <Typography variant="bodyM">{feature}</Typography>
              </StyledFeatures>
            ))}
          </>
        ) : (
          <Grid container justifyContent="center" alignItems="center" flex={1}>
            <Grid
              item
              container
              xs={12}
              gap={6}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress sx={{ marginTop: "30px" }} />
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const PlanCardPromo = () => {
    const displayPromo =
      isBasicPlan && !payment?.customerKey && !userPlan?.trial;
    return (
      <>
        {displayPromo && (
          <PromoOfferContainer>
            <Grid container alignItems="center">
              <LocalOfferOutlinedIconStyled />
              <Typography
                variant="labelS"
                component="h4"
                sx={{ color: theme.palette.black[800] }}
              >
                {t("pricing.plan.basic.promo.title")}
              </Typography>
            </Grid>
            <Typography
              variant="bodyS"
              sx={{
                color: theme.palette.black[600],
                marginTop: theme.spacing(0.5),
              }}
            >
              {t("pricing.plan.basic.promo.text")}
            </Typography>
          </PromoOfferContainer>
        )}
      </>
    );
  };

  const showDowngradeButton = () => {
    const currentStructure =
      planAvailability[currentPlan as keyof typeof planAvailability];

    const buttonDowngrade = currentStructure?.downgrade?.includes(plan);
    return buttonDowngrade;
  };

  const PlanCardCol = () => {
    return (
      <>
        <PlanCardHeader />
        <PlanCardPrice />
        {!showDowngradeButton() && <PlanCardCTA />}

        <PlanCardFeatures />
        <PlanCardPromo />
      </>
    );
  };

  const PlanCardRow = () => {
    return (
      <>
        <StyledPlanCardDetails>
          <PlanCardHeader />
          <PlanCardPrice />
          {!showDowngradeButton() && <PlanCardCTA />}
          <PlanCardPromo />
        </StyledPlanCardDetails>

        <StyledPlanCardDetails>
          <PlanCardFeatures />
        </StyledPlanCardDetails>
      </>
    );
  };

  return (
    <PlanCardWrapper $fullWidthCard={plan === plans.ENTERPRISE}>
      {plan !== plans.ENTERPRISE ? <PlanCardCol /> : <PlanCardRow />}
    </PlanCardWrapper>
  );
};

export default PlanCard;
