const routes = {
  HOME_WITHOUT_FORWARD_SLASH: "",
  HOME: "/",
  SEND: "/send",
  RECEIVER: "/receiver",
  WORKSPACES: "/workspaces",
  RECEIVED_TRANSFERS: "/received-transfers",
  LOGIN: "/login",
  LOGIN_RESET_PASSWORD: "/login/reset-password",
  LOGIN_RESET_PASSWORD_SENT: "/login/reset-password/sent",
  LOGIN_RESET_PASSWORD_SUCCESS: "/login/reset-password/success",
  LOGIN_RESET_PASSWORD_EXPIRED: "/login/reset-password/expired",
  LOGIN_RESET_PASSWORD_RESET: "/login/reset-password/reset",
  REGISTER: "/register",
  REGISTER_VERIFY: "/register/verify",
  REGISTER_SUCCESS: "/register/success",
  REGISTER_EXPIRED: "/register/expired",
  PRICING: "/pricing",
  ERROR: "/error",
  ACCOUNT: "/account",
  ACCOUNT_RESET_PASSWORD_SENT: "/account/reset-password/sent",
  ACCOUNT_PREFERENCES: "/account/preferences",
  CHECKOUT: "/checkout",
  CHECKOUT_SUCCESS: "/checkout/success",
  UPLOAD: "/upload",
  UPLOAD_ANALYZE: "/upload",
  SUCCESS: "/success",
  EXPIRED: "/expired",
  VIRUS_DETECTED: "/virusdetected",
  USER: "/user",
};

export default routes;
